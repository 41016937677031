import {ghTrustedTypes} from '@github-ui/trusted-types'

export const turboPolicy = ghTrustedTypes.createPolicy('turbo', {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createHTML: (html: string, response: Response) => {
    return html
  },

  createScriptURL: (url: string) => {
    return url
  },
})
