import {announce} from '@github-ui/aria-live'
import {ssrSafeDocument} from '@github-ui/ssr-utils'

export function setTitle(title: string) {
  if (!ssrSafeDocument) return

  const newTitle = ssrSafeDocument.createElement('title')
  newTitle.textContent = title

  const oldTitle = ssrSafeDocument.querySelector('title')

  if (oldTitle) {
    oldTitle.replaceWith(newTitle)
  } else {
    ssrSafeDocument.head.appendChild(newTitle)
  }

  announce(title)
}
